const firebaseConfigProd = {
	apiKey: 'AIzaSyCmaTbXb-mHv1WMCQiulvcaXr01rN1DlDE',
	authDomain: 'anlar-dev.firebaseapp.com',
	projectId: 'anlar-dev',
	storageBucket: 'anlar-dev.appspot.com',
	messagingSenderId: '578823449213',
	appId: '1:578823449213:web:2986bc103e221b494f9f14',
	measurementId: 'G-73EXLWQFTE'
};

const firebaseConfigDev = {
	apiKey: 'AIzaSyCaIVN-ka4N9t-O4YnZyAmP_UkjVIykjJY',
	authDomain: 'anlar-test.firebaseapp.com',
	projectId: 'anlar-test',
	storageBucket: 'anlar-test.appspot.com',
	messagingSenderId: '120036566167',
	appId: '1:120036566167:web:b7f2b6d9dbf70f76ab35d2',
	measurementId: 'G-P14Y4SPW6R'
};

export default firebaseConfigDev;
