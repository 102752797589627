import dashboard from './dashboard';
import admin from './admin';
import general from './general';
import library from './library';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
	items: [dashboard, library, admin, general]
};

export default menuItems;
