import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { OpportunityProvider } from 'contexts/OpportunityContext';
import { LibraryProvider } from 'contexts/LibraryContext';

// page routing
const Dashboard = Loadable(lazy(() => import('views/admin/dashboard/index')));
const Teams = Loadable(lazy(() => import('views/admin/collection-views/teams/index')));
const Users = Loadable(lazy(() => import('views/admin/collection-views/users/index')));
const ProjectTypes = Loadable(
	lazy(() => import('views/admin/collection-views/project-types/index'))
);
const OpportunityStatus = Loadable(
	lazy(() => import('views/admin/collection-views/opportunity-status/index'))
);
const OpportunityOwner = Loadable(
	lazy(() => import('views/admin/collection-views/opportunity-owner/index'))
);
const Sources = Loadable(lazy(() => import('views/admin/collection-views/sources/index')));
const Chances = Loadable(lazy(() => import('views/admin/collection-views/chances/index')));
const CompanyTypes = Loadable(
	lazy(() => import('views/admin/collection-views/company-types/index'))
);
const CompanyContacts = Loadable(
	lazy(() => import('views/admin/collection-views/company-contacts/index'))
);
const Companies = Loadable(lazy(() => import('views/admin/collection-views/companies/index')));
const Opportunities = Loadable(
	lazy(() => import('views/admin/collection-views/opportunities/index'))
);
const Projects = Loadable(lazy(() => import('views/admin/collection-views/projects/index')));
const ProjectDetails = Loadable(
	lazy(() => import('views/admin/collection-views/projects/DetailsView'))
);
const OpportunityDetails = Loadable(
	lazy(() => import('views/admin/collection-views/opportunities/DetailsView'))
);
const Projections = Loadable(lazy(() => import('views/admin/projections')));

// ==============================|| MAIN ROUTING ||============================== //
const OperationsRoutes = {
	path: '/',
	element: (
		<AuthGuard>
			<MainLayout />
		</AuthGuard>
	),
	children: [
		{
			path: '/',
			element: <Dashboard />
		},
		{
			path: '/dashboard',
			element: <Dashboard />
		},
		{
			path: '/admin/teams',
			element: <Teams />
		},
		{
			path: '/admin/users',
			element: <Users />
		},
		{
			path: '/admin/project-types',
			element: <ProjectTypes />
		},
		{
			path: '/admin/opportunity-status',
			element: <OpportunityStatus />
		},
		{
			path: '/admin/opportunity-owner',
			element: <OpportunityOwner />
		},
		{
			path: '/admin/sources',
			element: <Sources />
		},
		{
			path: '/admin/chances',
			element: <Chances />
		},
		{
			path: '/admin/company-types',
			element: <CompanyTypes />
		},
		{
			path: '/admin/company-contacts',
			element: <CompanyContacts />
		},
		{
			path: '/admin/companies',
			element: (
				<OpportunityProvider>
					<Companies />
				</OpportunityProvider>
			)
		},
		{
			path: '/general/opportunities',
			element: (
				<OpportunityProvider>
					<Opportunities />
				</OpportunityProvider>
			)
		},
		{
			path: '/general/opportunities/:id',
			element: (
				<OpportunityProvider>
					<OpportunityDetails />
				</OpportunityProvider>
			)
		},
		{
			path: '/general/opportunities/:id/projections',
			element: <Projections />
		},
		{
			path: '/general/projects',

			element: <Projects />
		},
		{
			path: '/general/projects/:id',
			element: (
				<OpportunityProvider>
					<ProjectDetails />
				</OpportunityProvider>
			)
		}
	]
};

export default OperationsRoutes;
