import { httpsCallable } from 'firebase/functions';
import { functions } from 'utils/firebaseInitialize';

export const getList = async (col) => {
	const data = httpsCallable(functions, 'list');

	const query = {
		col: col
	};

	const dataList = await data(query);

	return dataList;
};
export const getRecordById = async (col, id) => {
	const data = httpsCallable(functions, 'getRecordById');

	const query = {
		col,
		id
	};

	const record = await data(query);

	return record;
};

export const getListQuery = async (col, field, value, condition, limit) => {
	const data = httpsCallable(functions, 'listBySimpleQuery');

	const query = {
		col: col,
		field: field,
		condition: condition,
		value: value,
		...(limit && { limit })
	};

	const dataList = await data(query);

	return dataList.data;
};

export const getSubCollection = async (col, id, sub) => {
	const subCollection = httpsCallable(functions, 'getSubCollection');

	const data = {
		col,
		id,
		sub
	};

	const dataList = await subCollection(data);
	return dataList.data;
};
export const addSubCollection = async (col, id, sub, data) => {
	const create = httpsCallable(functions, 'createSubcollection');
	data.col = col;
	data.ref = id;
	data.sub = sub;

	const newData = await create(data);

	return newData;
};
export const updateSubcollection = async (col, id, sub, newData) => {
	newData.col = col;
	newData.sub = sub;
	newData.parentID = id;

	const update = httpsCallable(functions, 'updateSubcollection');

	await update(newData)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return 'error';
		});
};

export const deleteSubCollection = async (col, id, sub, recordId) => {
	const deleteSub = httpsCallable(functions, 'deleteSubcollection');
	const data = { col, id, sub, recordId };

	const deleteData = await deleteSub(data);
	return deleteData;
};

export const updateRecord = async (newData) => {
	const update = httpsCallable(functions, 'update');
	await update(newData)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return 'error';
		});
};

export const getCount = async (col, field, value, condition) => {
	const data = httpsCallable(functions, 'getCount');

	const query = {
		col,
		field,
		condition,
		value
	};

	const count = await data(query);

	return count.data;
};
