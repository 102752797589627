export const JWT_API = {
	secret: 'SECRET-KEY',
	timeout: '1 days'
};

// PROD KEYS //
// export const FIREBASE_API = {
// 	apiKey: 'AIzaSyCmaTbXb-mHv1WMCQiulvcaXr01rN1DlDE',
// 	authDomain: 'anlar-dev.firebaseapp.com',
// 	projectId: 'anlar-dev',
// 	storageBucket: 'anlar-dev.appspot.com',
// 	messagingSenderId: '578823449213',
// 	appId: '1:578823449213:web:2986bc103e221b494f9f14',
// 	measurementId: 'G-73EXLWQFTE'
// };

export const FIREBASE_API = {
	apiKey: 'AIzaSyCaIVN-ka4N9t-O4YnZyAmP_UkjVIykjJY',
	authDomain: 'anlar-test.firebaseapp.com',
	projectId: 'anlar-test',
	storageBucket: 'anlar-test.appspot.com',
	messagingSenderId: '120036566167',
	appId: '1:120036566167:web:b7f2b6d9dbf70f76ab35d2',
	measurementId: 'G-P14Y4SPW6R'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard';

const config = {
	fontFamily: `'Montserrat', sans-serif`,
	borderRadius: 8,
	outlinedFilled: true,
	navType: 'light', // light, dark
	presetColor: 'theme1', // default, theme1, theme2, theme3, theme4, theme5, theme6
	locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
	rtlLayout: false,
	container: false
};

export default config;
