import PropTypes from 'prop-types';
import { createContext, useState, useContext, useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { dispatch } from 'store';
import { SET_FTE } from 'store/actions';
import { getCount, getListQuery } from 'API/basicCalls';

const AppContext = createContext(null);

export const AppProvider = ({ children }) => {
	const { deleteDocument, addSubCollection, formSubmissionUpdate, user } = useAuth();

	const [alert, setAlert] = useState({
		action: true,
		open: false,
		message: '',
		messages: [],
		anchorOrigin: {
			vertical: 'top',
			horizontal: 'right'
		},
		variant: 'alert',
		alertSeverity: 'success',
		transition: 'SlideUp',
		close: false,
		actionButton: false
	});
	const [refresh, setRefresh] = useState(false);
	const [clients, setClients] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [opportunityOwners, setOpportunityOwners] = useState([]);
	const [opportunityStatuses, setOpportunityStatuses] = useState([]);
	const [projectTypes, setProjectTypes] = useState([]);
	const [sources, setSources] = useState([]);
	const [bpas, setBpas] = useState([]);
	const [teams, setTeams] = useState([]);
	const [chances, setChances] = useState([]);
	const [rfpManagers, setRfpManagers] = useState([]);
	const [users, setUsers] = useState([]);
	const [subContractors, setSubContractors] = useState([]);
	const [endClients, setEndClients] = useState([]);
	const [allProjects, setAllProjects] = useState([]);
	const [editing, setEditing] = useState(false);
	const [budgetTypes, setBudgetTypes] = useState([]);

	useEffect(() => {
		getRecordsGeneral();
	}, []);

	const handleEdit = (title) => {
		setEditing({
			edit: title ? true : false,
			title: title || ''
		});
	};

	const handleSave = () => {
		handleEdit();
	};

	const deleteFile = async (doc) => {
		console.log('delete this one:', doc);
		const res = await deleteDocument('documents', doc);

		if (res !== 'error') {
			setAlert({
				...alert,
				open: true,
				message: `Deleted Document`,
				alertSeverity: 'success'
			});
			triggerRefresh();
		} else {
			setAlert({
				...alert,
				open: true,
				message: `Error deleting document`,
				alertSeverity: 'error'
			});
		}
	};

	const recordRetrivalObjs = [
		{
			col: 'companies',
			setData(data, clientData) {
				setClients(clientData);
				setEndClients(data);
				setSubContractors(data);
				setCompanies(data);
			}
		},
		{
			col: 'opportunityOwner',
			setData(data) {
				setOpportunityOwners(data);
			}
		},
		{
			col: 'opportunityStatus',
			setData(data) {
				setOpportunityStatuses(data);
			}
		},
		{
			col: 'projectTypes',
			setData(data) {
				setProjectTypes(data);
			}
		},
		{
			col: 'sources',
			setData(data) {
				setSources(data);
			}
		},
		{
			col: 'bpas',
			setData(data) {
				setBpas(data);
			}
		},
		{
			col: 'teams',
			setData(data) {
				setTeams(data);
			}
		},
		{
			col: 'chances',
			setData(data) {
				setChances(data);
			}
		},
		{
			col: 'users',
			setData(data) {
				setRfpManagers(data);
				setUsers(data);
			}
		},
		{
			col: 'budgetTypes',
			setData(data) {
				setBudgetTypes(data);
			}
		}
	];

	const getRecordsGeneral = async () => {
		recordRetrivalObjs.forEach(async (item) => {
			let records = await getListQuery(item.col, 'status', 'Active', '==');

			let data = records
				.sort((a, b) => a.name?.localeCompare(b.name))
				.sort((a, b) => a.lastName?.localeCompare(b.lastName))
				.map((record) => {
					if (item.col === 'users') {
						return {
							id: record.id,
							name: `${record.firstName} ${record.lastName}`,
							email: record.email,
							teams: record.teams,
							permissions: record.permissions,
							companies: record.companies,
							manager: record.manager
						};
					} else if (item.col === 'budgetTypes') {
						return {
							id: record.id,
							name: record.name,
							projections: record.projections
						};
					} else
						return {
							id: record.id,
							name: record.name
						};
				});

			if (item.col == 'companies') {
				let clientData = records.map((record) => {
					return {
						id: record.id,
						name:
							item.col == 'users'
								? `${record.firstName} ${record.lastName}`
								: record.name,
						type: record.companyType.name,
						...(record.hasBPA && { hasBPA: record.hasBPA })
					};
				});

				clientData.unshift({
					id: 0,
					name: '-- Add New Client --'
				});

				item.setData(data, clientData);
			} else {
				item.setData(data);
			}
		});
	};

	const triggerRefresh = () => setRefresh(refresh ? false : true);

	const handleSubcollectionSubmit = async (collection, subcollection, type, id, data) => {
		const res = await addSubCollection(collection, id, subcollection, data);

		if (res !== 'error') {
			setAlert({
				...alert,
				open: true,
				message: `${type} Added`,
				alertSeverity: 'success'
			});
		} else {
			setAlert({
				...alert,
				open: true,
				message: `Error adding ${type}`,
				alertSeverity: 'error'
			});
		}
	};

	const updateProject = async (data) => {
		const res = await formSubmissionUpdate('opportunities', data);
		triggerRefresh();
		return res;
	};

	return (
		<AppContext.Provider
			value={{
				alert,
				setAlert,
				editing,
				setEditing,
				handleEdit,
				deleteFile,
				handleSave,
				opportunityOwners,
				opportunityStatuses,
				projectTypes,
				sources,
				bpas,
				teams,
				chances,
				rfpManagers,
				users,
				subContractors,
				endClients,
				clients,
				companies,
				triggerRefresh,
				refresh,
				handleSubcollectionSubmit,
				updateProject,
				budgetTypes,
				getRecordsGeneral
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

AppProvider.propTypes = {
	children: PropTypes.node
};

export default AppContext;

export const AppFunctions = () => {
	return useContext(AppContext);
};
