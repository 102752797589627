import PropTypes from 'prop-types';
import { createContext, useState, useContext, useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { AppFunctions } from './AppContext';
import { searchDynamicQuery } from 'API/libraryCalls';

const LibraryContext = createContext(null);

const initialContent = {
	opportunity: { id: '', name: '', status: '' },
	pastOpp: { id: '', name: '' },
	externalContractor: { id: '', name: '' },
	internalStaffName: { id: '', name: '' },
	document: { id: '', name: '' },
	image: { name: '', src: '' },
	callOutBox: { name: '', path: '' },
	sectionTitle: { id: '', name: '' },
	diagramImage: false,
	callOutBox: false,
	rfpContent: '',
	teamArray: [],
	teams: [],
	proposalYear: ''
};

const initialSearchTerms = {
	searchWords: '',
	proposalYear: null,
	pastNickname: { id: '', name: '' },
	opportunity: { id: '', name: '' },
	oppStatus: { id: '', name: '' },
	pastOpp: { id: '', name: '' },
	externalContractor: { id: '', name: '' },
	internalStaffName: { id: '', name: '' },
	teams: [],
	sectionTitle: [],
	diagramImage: false,
	callOutBox: false,
	proposalYear: ''
};

export const LibraryProvider = ({ children }) => {
	const { getList, formSubmissionCreate, uploadDocument } = useAuth();
	const { alert, setAlert, triggerRefresh } = AppFunctions();

	const [clear, setClear] = useState(false);
	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState('');
	const [searching, setSearching] = useState(false);
	const [content, setContent] = useState([]);
	const [sectionTitles, setSectionTitles] = useState([]);
	const [opportunities, setOpportunities] = useState([]);
	const [status, setStatus] = useState([]);
	const [results, setResults] = useState([]);
	const [newContent, setNewContent] = useState(initialContent);
	const [searchTerms, setSearchTerms] = useState(initialSearchTerms);
	const [searchWarning, setSearchWarning] = useState({
		show: false,
		message: ''
	});

	useEffect(() => {
		getSectionTitles();
		getOpportunities();
		getOpportunityStatus();
	}, []);

	const getContent = async () => {
		const res = await getList('rfpContent');

		setContent(res);
	};

	const getSectionTitles = async () => {
		const res = await getList('sectionTitles');
		setSectionTitles(res);
		setLoading(false);
	};

	const getOpportunities = async () => {
		const res = await getList('opportunities');
		const formatted = res
			.map((item) => ({
				id: item.id,
				name: item.solicitationName.trim(),
				status: item.opportunityStatus,
				teams: item.teamsInvolved,
				dueDate: item.dueDate
			}))
			.sort((a, b) => a.name.localeCompare(b.name));
		setOpportunities(formatted);
		setLoading(false);
	};

	const getOpportunityStatus = async () => {
		const res = await getList('opportunityStatus');
		const formatted = res
			.reduce((acc, item) => {
				if (item.status === 'Active') {
					acc.push({ id: item.id, name: item.name });
				}
				return acc;
			}, [])
			.sort((a, b) => a.name.localeCompare(b.name));

		setStatus(formatted);
	};

	const handleSearch = async () => {
		setSearching(true);
		setLoading(true);
		const searchResults = await searchDynamicQuery(searchTerms);
		setResults(searchResults.data.response);
		setContent(searchResults.data.response);
		if (searchResults.data.numberOfResults > 1000) {
			setSearchWarning({
				show: true,
				message: `Your search returned ${searchResults.data.numberOfResults} results. The first 1,000 results are displayed below. Try different search criteria to narrow down your search results.`
			});
		} else {
			setSearchWarning({
				show: false,
				message: ``
			});
		}
		setLoading(false);
	};

	const triggerClear = () => setClear(clear ? false : true);

	const clearSearch = () => {
		setSearchTerms(initialSearchTerms);
		setSearching(false);
		setContent([]);
		setResults([]);
		setSearchWarning({
			show: false,
			message: ``
		});
	};

	const addContenttoLibrary = async () => {
		const res = await formSubmissionCreate('rfpContent', newContent);

		if (res !== 'error') {
			setAlert({
				...alert,
				open: true,
				message: `RFP Content Created!`,
				alertSeverity: 'success'
			});
			triggerRefresh();
			resetNewContent();
		} else {
			setAlert({
				...alert,
				open: true,
				message: `Error Creating RFP COntent. Please try again or contact support.`,
				alertSeverity: 'error'
			});
		}
	};

	const resetNewContent = () => {
		setNewContent(initialContent);
	};

	return (
		<LibraryContext.Provider
			value={{
				content,
				results,
				searchTerms,
				setSearchTerms,
				search,
				setSearch,
				setSearching,
				searching,
				setResults,
				handleSearch,
				clearSearch,
				addContenttoLibrary,
				sectionTitles,
				opportunities,
				newContent,
				setNewContent,
				resetNewContent,
				loading,
				triggerClear,
				clear,
				status,
				searchWarning
			}}
		>
			{children}
		</LibraryContext.Provider>
	);
};

LibraryProvider.propTypes = {
	children: PropTypes.node
};

export default LibraryContext;

export const LibraryFunctions = () => {
	return useContext(LibraryContext);
};
