import { useState, useEffect } from 'react';

// material-ui
import { Alert, Button, Fade, Grow, IconButton, Slide } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// assets
import CloseIcon from '@mui/icons-material/Close';

import { AppFunctions } from 'contexts/AppContext';

// animation function
function TransitionSlideLeft(props) {
	return <Slide {...props} direction='left' />;
}

function TransitionSlideUp(props) {
	return <Slide {...props} direction='up' />;
}

function TransitionSlideRight(props) {
	return <Slide {...props} direction='right' />;
}

function TransitionSlideDown(props) {
	return <Slide {...props} direction='down' />;
}

function GrowTransition(props) {
	return <Grow {...props} />;
}

// animation options
const transition = {
	SlideLeft: TransitionSlideLeft,
	SlideUp: TransitionSlideUp,
	SlideRight: TransitionSlideRight,
	SlideDown: TransitionSlideDown,
	Grow: GrowTransition,
	Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
	const [open, setOpen] = useState(false);
	const { alert, setAlert } = AppFunctions();
	// const alert = useSelector((state) => alert);

	const handleClose = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert({ ...alert, open: false });
	};

	return (
		<>
			{/* default snackbar */}
			{alert.variant === 'default' && (
				<MuiSnackbar
					anchorOrigin={alert.anchorOrigin}
					open={alert.open}
					autoHideDuration={6000}
					severity={alert.alertSeverity}
					onClose={(e, v) => handleClose(v)}
					message={alert.message}
					TransitionComponent={transition[alert.transition]}
					action={
						<>
							<Button color='secondary' size='small' onClick={() => handleClose()}>
								CLOSE
							</Button>
							<IconButton
								size='small'
								aria-label='close'
								color='inherit'
								onClick={() => handleClose()}
							>
								<CloseIcon fontSize='small' />
							</IconButton>
						</>
					}
				/>
			)}

			{/* alert snackbar */}
			{alert.variant === 'alert' && (
				<MuiSnackbar
					TransitionComponent={transition[alert.transition]}
					anchorOrigin={alert.anchorOrigin}
					open={alert.open}
					autoHideDuration={2000}
					onClose={(e, v) => handleClose(v)}
				>
					<Alert
						variant='filled'
						severity={alert.alertSeverity}
						sx={{
							bgcolor: `${alert.alertSeverity}.dark`,
							color: alert.alertSeverity === 'warning' ? 'grey.800' : ''
						}}
						action={
							<>
								{alert.actionButton !== false && (
									<Button
										color='secondary'
										size='small'
										onClick={() => handleClose()}
									>
										CLOSE
									</Button>
								)}
								{alert.close !== false && (
									<IconButton
										size='small'
										aria-label='close'
										color='inherit'
										onClick={() => handleClose()}
									>
										<CloseIcon fontSize='small' />
									</IconButton>
								)}
							</>
						}
					>
						{alert.message}
					</Alert>
				</MuiSnackbar>
			)}
		</>
	);
};

export default Snackbar;
