// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = {
	IconDashboard
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const dashboard = {
	id: 'dashboard',
	title: <FormattedMessage id='dashboard' />,
	type: 'group',
	children: [
		{
			id: 'dashboard',
			title: <FormattedMessage id='dashboard' />,
			type: 'item',
			url: '/dashboard',
			icon: icons.IconDashboard,
			breadcrumbs: false
		}
	]
};

export default dashboard;
