// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBooks } from '@tabler/icons';

// constant
const icons = {
	IconBooks
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const library = {
	id: 'library',
	title: <FormattedMessage id='library' />,
	type: 'group',
	children: [
		{
			id: 'library',
			title: <FormattedMessage id='rfplibrary' />,
			type: 'item',
			url: '/rfp-content-library',
			icon: icons.IconBooks,
			breadcrumbs: false
		}
	]
};

export default library;
