import { httpsCallable } from 'firebase/functions';
import { functions } from 'utils/firebaseInitialize';

export const searchDynamicQuery = async (filters) => {
	const data = httpsCallable(functions, 'test');

	const dataList = await data(filters);

	return dataList;
};
