// action - state management
import { SET_SERIES, RESET_SERIES, SET_PROJECTS, SET_ACTUALS } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
	series: [],
	projects: [],
	actuals: [],
	overallActuals: [],
	type: ''
};

const dashboardReducer = (state = initialState, payload) => {
	switch (payload.type) {
		case SET_SERIES: {
			const { series } = payload;
			return {
				...state,
				series: series
			};
		}
		case SET_PROJECTS: {
			const { projects, overallActuals } = payload;
			return {
				...state,
				projects: projects,
				overallActuals: overallActuals
			};
		}
		case SET_ACTUALS: {
			const { actuals } = payload;
			return {
				...state,
				actuals: actuals
			};
		}
		// case CLEAR_OBJECT: {
		// 	return {
		// 		...state,
		// 		data: {}
		// 	};
		// }
		// case SET_TYPE: {
		// 	const { dataType } = payload;
		// 	return {
		// 		...state,
		// 		type: dataType
		// 	};
		// }
		case RESET_SERIES: {
			return {
				...state,
				series: []
			};
		}
		default: {
			return { ...state };
		}
	}
};

export default dashboardReducer;
