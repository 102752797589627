import React from 'react';
import Error from './Error';

export class InnerErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError = (error) => {
		return { hasError: true };
	};

	componentDidUpdate(prevProps, _previousState) {
		if (!this.props.hasError && prevProps.hasError) {
			this.setState({ hasError: false });
		}
	}

	componentDidCatch = (error, info) => {
		this.props.setHasError(true);
	};

	render() {
		const { hasError } = this.state;
		const { children } = this.props;

		return hasError ? <Error /> : children;
	}
}
