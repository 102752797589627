// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
	Avatar,
	Button,
	Card,
	CardContent,
	Chip,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Stack,
	Typography
} from '@mui/material';

// assets
import {
	IconBrandTelegram,
	IconBuildingStore,
	IconMailbox,
	IconPhoto,
	IconBuilding,
	IconSquareCheck
} from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
	cursor: 'pointer',
	padding: 16,
	// '&:hover': {
	// 	background:
	// 		theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
	// },
	'& .MuiListItem-root': {
		padding: 0
	}
}));

const icons = {
	company: <IconBuilding />
};

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ data, markAsRead }) => {
	const theme = useTheme();

	const chipSX = {
		height: 24,
		padding: '0 6px'
	};
	const chipErrorSX = {
		...chipSX,
		color: theme.palette.orange.dark,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
		marginRight: '5px'
	};

	const chipWarningSX = {
		...chipSX,
		color: theme.palette.warning.dark,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
	};

	const chipSuccessSX = {
		...chipSX,
		color: theme.palette.success.dark,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
		height: 28
	};

	return (
		<List
			sx={{
				width: '100%',
				maxWidth: 330,
				py: 0,
				borderRadius: '10px',
				[theme.breakpoints.down('md')]: {
					maxWidth: 300
				},
				'& .MuiListItemSecondaryAction-root': {
					top: 22
				},
				'& .MuiDivider-root': {
					my: 0
				},
				'& .list-container': {
					pl: 7
				}
			}}
		>
			{data.map(({ type, message, dateCreated, read, title, id }) => (
				<>
					<ListItemWrapper key={id}>
						<ListItem alignItems='center'>
							<ListItemAvatar>
								<Avatar
									sx={{
										color: theme.palette.success.dark,
										backgroundColor:
											theme.palette.mode === 'dark'
												? theme.palette.dark.main
												: theme.palette.success.light,
										border:
											theme.palette.mode === 'dark' ? '1px solid' : 'none',
										borderColor: theme.palette.success.main
									}}
									alt={type}
								>
									{icons[type]}
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={title} />
							<ListItemSecondaryAction>
								<Grid container justifyContent='flex-end'>
									<Grid item xs={12}>
										<Typography variant='caption' display='block' gutterBottom>
											{new Date(
												dateCreated._seconds || dateCreated.seconds * 1000
											).toLocaleDateString()}
										</Typography>
									</Grid>
								</Grid>
							</ListItemSecondaryAction>
						</ListItem>
						<Grid container direction='column' className='list-container'>
							<Grid item xs={12} sx={{ pb: 2 }}>
								<Typography variant='subtitle2'>{message}</Typography>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									{!read ? (
										<>
											<Grid item>
												<Chip label='Unread' sx={chipErrorSX} />
											</Grid>
											<Grid item>
												<Chip label='New' sx={chipWarningSX} />
											</Grid>
											<Grid item xs={12} mt={1}>
												<Button
													startIcon={<IconSquareCheck />}
													onClick={() => markAsRead([{ id }])}
													color='success'
													variant='contained'
													disableElevation
												>
													Mark as read
												</Button>
											</Grid>
										</>
									) : (
										<>
											<Grid item>
												<Chip label='Read' sx={chipSuccessSX} />
											</Grid>
											{/* <Grid item xs={12} mt={1}>
												<Button
													startIcon={<IconSquareCheck />}
													onClick={() => markAsRead([{ id }])}
													color='success'
													variant='contained'
													disableElevation
												>
													Clear
												</Button>
											</Grid> */}
										</>
									)}
								</Grid>
							</Grid>
						</Grid>
					</ListItemWrapper>
					<Divider />
				</>
			))}
		</List>
	);
};

export default NotificationList;
