import PropTypes from 'prop-types';

import React, { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Fab,
	Grid,
	Tooltip,
	Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddTwoTone';
import EditTwoTone from '@mui/icons-material/EditTwoTone';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { CancelTwoTone } from '@mui/icons-material';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import SendIcon from '@mui/icons-material/Send';

import { AppFunctions } from 'contexts/AppContext';

// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCard = forwardRef(
	(
		{
			children,
			content,
			contentClass,
			darkTitle,
			secondary,
			sx = {},
			contentSX = {},
			title,
			handleAdd,
			button,
			subtitle,
			handleEditClick,
			...others
		},
		ref
	) => {
		const theme = useTheme();
		const { editing, handleSave, handleEdit } = AppFunctions();

		let buttonTitle;

		if (typeof title === 'string') {
			buttonTitle = title.split(' ');
			buttonTitle = buttonTitle[0];
		}

		const Buttons = () => {
			const addButton = (
				<Tooltip key={'add'} title={`Add ${buttonTitle}`}>
					<Fab
						onClick={() => handleAdd(title)}
						color='primary'
						size='small'
						sx={{ ml: 1, width: 24, height: 24, minHeight: 24 }}
					>
						<AddIcon fontSize='small' />
					</Fab>
				</Tooltip>
			);

			const editButton = (
				<Tooltip key={'edit'} title={`Edit ${buttonTitle}`}>
					<Fab
						onClick={handleEditClick}
						color='paper'
						size='small'
						sx={{ ml: 1, width: 24, height: 24, minHeight: 24 }}
					>
						<EditTwoTone fontSize='small' />
					</Fab>
				</Tooltip>
			);

			const saveButton = (
				<Tooltip key={'edit'} title={`Done Editing`}>
					<Fab
						onClick={() => handleSave(title)}
						color='success'
						size='large'
						sx={{ ml: 1, width: 30, height: 30, minHeight: 30 }}
					>
						<CheckCircleOutlineTwoToneIcon fontSize='small' />
					</Fab>
				</Tooltip>
			);

			const emailButton = (
				<Tooltip key={'sendEmail'} title={`Send Email`}>
					<Fab
						onClick={() => handleAdd('email')}
						color='info'
						size='small'
						sx={{ ml: 1, width: 24, height: 24, minHeight: 24 }}
					>
						<SendIcon
							sx={{
								fontSize: 15,
								position: 'absolute',
								color: '#fff',
								transform: 'rotate(-35deg)'
							}}
						/>
					</Fab>
				</Tooltip>
			);

			return (
				<>
					<Grid container alignItems='center'>
						<>
							{title === 'Decisions' && emailButton}
							{title !== 'Actual Costs' && title !== 'Projections' && addButton}
							{title !== 'Documents' &&
								title !== 'Decisions' &&
								title !== 'Actual Costs' &&
								title !== 'Contacts' &&
								editButton}
						</>
					</Grid>
				</>
			);
		};

		return (
			<Card
				ref={ref}
				sx={{
					border: '1px solid',
					borderColor:
						theme.palette.mode === 'dark'
							? theme.palette.dark.light + 15
							: theme.palette.primary.light,
					':hover': {
						boxShadow:
							theme.palette.mode === 'dark'
								? '0 2px 14px 0 rgb(33 150 243 / 10%)'
								: '0 2px 14px 0 rgb(32 40 45 / 8%)'
					},
					...sx
				}}
				{...others}
			>
				{/* card header and action */}
				{!darkTitle && title && (
					<CardHeader
						sx={{ p: 2.5 }}
						title={<Typography variant='h5'>{title}</Typography>}
						action={button ? <Buttons /> : secondary}
						subheader={subtitle ? subtitle : ''}
					/>
				)}
				{darkTitle && title && (
					<CardHeader
						sx={{ p: 2.5 }}
						title={<Typography variant='h4'>{title}</Typography>}
						action={secondary}
					/>
				)}

				{/* content & header divider */}
				{title && (
					<Divider
						sx={{
							opacity: 1,
							borderColor:
								theme.palette.mode === 'dark'
									? theme.palette.dark.light + 15
									: theme.palette.primary.light
						}}
					/>
				)}

				{/* card content */}
				{content && (
					<CardContent sx={{ p: 2.5, ...contentSX }} className={contentClass || ''}>
						{children}
					</CardContent>
				)}
				{!content && children}
			</Card>
		);
	}
);

SubCard.propTypes = {
	children: PropTypes.node,
	content: PropTypes.bool,
	contentClass: PropTypes.string,
	darkTitle: PropTypes.bool,
	secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
	sx: PropTypes.object,
	contentSX: PropTypes.object,
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

SubCard.defaultProps = {
	content: true
};

export default SubCard;
