// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const SET_FTE = 'SET_FTE';
export const UPDATE_USER = 'UPDATE_USER';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

//data management reducer
export const SET_DATA = '@data/SET_DATA';
export const SET_TYPE = '@data/RESET_DATA';
export const RESET_DATA = '@data/RESET_DATA';
export const CLEAR_OBJECT = '@data/CLEAR_OBJECT';

//opportunity reducer
export const SET_OPP = '@opportunity/SET_OPP';
export const RESET_OPP = '@opportunity/RESET_OPP';
export const SET_EDIT = '@opportunity/SET_EDIT';
export const CLEAR_EDIT = '@opportunity/SET_EDIT';

//dashboard reducer
export const SET_SERIES = '@dashboard/SET_SERIES';
export const RESET_SERIES = '@dashboard/RESET_SERIES';
export const SET_PROJECTS = '@dashboard/SET_PROJECTS';
export const SET_ACTUALS = '@dashboard/SET_ACTUALS';
export const RESET_PROJECTS = '@dashboard/RESET_PROJECTS';
