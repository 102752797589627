import { Timestamp } from 'firebase/firestore';
import { baseURI2 } from 'store/constant';

export const transformToTimestamp = (date) => {
	return Timestamp.fromDate(new Date(date));
};

export const createYears = (numYears) => {
	const currentYear = new Date().getFullYear();
	let startYear = currentYear - numYears;
	let yearArray = [startYear];

	for (let i = startYear++; i < currentYear; i++) {
		yearArray.push(startYear++);
	}

	return yearArray;
};

export const getMonths = (months) => {
	const d = new Date();
	const newDate = d.setMonth(d.getMonth() - months);

	return newDate;
};

const getMonthCount = (month) => {
	const sometime = new Date(`${month} 1`);
	const monthNumber = sometime.getMonth() + 1;

	return monthNumber;
};

export const formatMonthNumber = (month) => {
	const number = getMonthCount(month);
	const monthNumber = `${number < 10 ? '0' + number : number} - ${month}`;
	return monthNumber;
};

export const getYearOptions = (startAtCurrent = false) => {
	const currentYear = new Date().getFullYear();

	const startYear = startAtCurrent ? currentYear : currentYear - 2;
	const endYear = currentYear + 5;
	const step = 1;

	const options = Array.from(
		{ length: (endYear - startYear) / step + 1 },
		(value, index) => startYear + index * step
	);

	return options;
};

export const getMonthArray = (count, current) => {
	let arr = [];
	let index = 0;
	while (index++ <= count) {
		const monthNumber = current++;
		const month = new Date();
		month.setMonth(monthNumber);

		arr.push({
			month: month.toLocaleString('en-US', {
				month: 'long'
			}),
			year: new Date().getFullYear()
		});
	}
	return arr;
};

export const returnProfilePicture = ({ userInfo }) => {
	if (userInfo) {
		const { profilePicture } = userInfo;
		if (!profilePicture) {
			return '';
		} else if (profilePicture.name) {
			return `${baseURI2}${profilePicture.name}?alt=media`;
		} else {
			return profilePicture;
		}
	}
};
