// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDiamond } from '@tabler/icons';
import PolylineTwoToneIcon from '@mui/icons-material/PolylineTwoTone';

// constant
const icons = {
	IconDiamond,
	PolylineTwoToneIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const general = {
	id: 'General',
	title: <FormattedMessage id='General' />,
	type: 'group',
	children: [
		{
			id: 'Opportunities',
			title: <FormattedMessage id='Opportunities' />,
			type: 'item',
			url: '/general/opportunities',
			icon: icons.IconDiamond,
			breadcrumbs: false
		},
		{
			id: 'Projects',
			title: <FormattedMessage id='Projects' />,
			type: 'item',
			url: '/general/projects',
			icon: icons.PolylineTwoToneIcon,
			breadcrumbs: false
		}
	]
};

export default general;
