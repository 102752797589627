import { collection, query, where, getDocs, doc, updateDoc, addDoc } from 'firebase/firestore';
import { db } from 'utils/firebaseInitialize';
import { transformToTimestamp } from 'utils/utilFunctions';

const emailsCol = collection(db, 'emails');
const notificationsCol = collection(db, 'notifications');

export const getUserNotificationsByRoles = async ({ roles = [] }) => {
	const q = query(notificationsCol, where('roles', 'array-contains-any', roles));

	const querySnapshot = await getDocs(q);

	if (querySnapshot.empty) {
		return [];
	} else {
		const data = querySnapshot.docs.map((doc) => {
			return { id: doc.id, ...doc.data() };
		});
		return data;
	}
};
