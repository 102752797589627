// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
	IconDashboard,
	IconBrandAsana,
	IconUser,
	IconBrandFlickr,
	IconBoxMultiple,
	IconCrown,
	IconAffiliate,
	IconListNumbers,
	IconAlignLeft,
	IconNotebook,
	IconBuilding,
	IconChartCandle,
	IconChartInfographic,
	IconMail
} from '@tabler/icons';

// constant
const icons = {
	IconDashboard,
	IconBrandAsana,
	IconUser,
	IconBrandFlickr,
	IconBoxMultiple,
	IconCrown,
	IconAffiliate,
	IconListNumbers,
	IconAlignLeft,
	IconNotebook,
	IconBuilding,
	IconChartCandle,
	IconChartInfographic,
	IconMail
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const admin = {
	id: 'admin',
	title: <FormattedMessage id='admin' />,
	type: 'group',
	user: false,
	role: 'Admin',
	children: [
		{
			id: 'Data-Management',
			title: <FormattedMessage id='Data-Management' />,
			type: 'collapse',
			url: '/admin/data-management',
			icon: icons.IconChartCandle,
			breadcrumbs: false,
			frontDesk: false,
			children: [
				{
					id: 'Teams',
					title: <FormattedMessage id='Teams' />,
					type: 'item',
					url: '/admin/teams',
					icon: icons.IconBrandAsana
				},
				{
					id: 'Users',
					title: <FormattedMessage id='Users' />,
					type: 'item',
					url: '/admin/users',
					icon: icons.IconUser
				},
				{
					id: 'Project-Types',
					title: <FormattedMessage id='Project-Types' />,
					type: 'item',
					url: '/admin/project-types',
					icon: icons.IconBrandFlickr
				},
				{
					id: 'Opportunity-Status',
					title: <FormattedMessage id='Opportunity-Status' />,
					type: 'item',
					url: '/admin/opportunity-status',
					icon: icons.IconBoxMultiple
				},
				{
					id: 'Opportunity-Owner',
					title: <FormattedMessage id='Opportunity-Owner' />,
					type: 'item',
					url: '/admin/opportunity-owner',
					icon: icons.IconCrown
				},
				{
					id: 'Sources',
					title: <FormattedMessage id='Sources' />,
					type: 'item',
					url: '/admin/sources',
					icon: icons.IconAffiliate
				},
				{
					id: 'Chances',
					title: <FormattedMessage id='Chances' />,
					type: 'item',
					url: '/admin/chances',
					icon: icons.IconListNumbers
				},
				{
					id: 'Company-Types',
					title: <FormattedMessage id='Company-Types' />,
					type: 'item',
					url: '/admin/company-types',
					icon: icons.IconAlignLeft
				},
				{
					id: 'Company-Contacts',
					title: <FormattedMessage id='Company-Contacts' />,
					type: 'item',
					url: '/admin/company-contacts',
					icon: icons.IconNotebook
				},
				{
					id: 'Companies',
					title: <FormattedMessage id='Companies' />,
					type: 'item',
					url: '/admin/companies',
					icon: icons.IconBuilding
				},
				{
					id: 'Salary Projections',
					title: <FormattedMessage id='Salary-Projections' />,
					type: 'item',
					url: '/admin/salary-projections',
					icon: icons.IconChartInfographic
				},
				{
					id: 'Emails',
					title: <FormattedMessage id='Emails' />,
					type: 'item',
					url: '/admin/emails',
					icon: icons.IconMail
				}
			]
		}
	]
};

export default admin;
