import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

// routes
import AdminRoutes from './AdminRoutes';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import LeadRoutes from './LeadRoutes';
import Loadable from 'ui-component/Loadable';
import OperationsRoutes from './OperationsRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
	const { user } = useAuth();

	let routes = [AdminRoutes, AuthenticationRoutes, LoginRoutes, MainRoutes, OperationsRoutes];

	if (user?.permissions?.includes('Admin')) {
		routes = [AdminRoutes, AuthenticationRoutes, LoginRoutes, MainRoutes];
	} else if (
		user?.permissions?.includes('Team Lead') ||
		user?.permissions?.includes('Project Manager')
	) {
		routes = [LeadRoutes, AuthenticationRoutes, LoginRoutes, MainRoutes];
	} else if (user?.permissions?.includes('Operations')) {
		routes = [OperationsRoutes, AuthenticationRoutes, LoginRoutes, MainRoutes];
	} else {
		routes = [MainRoutes, LoginRoutes, AuthenticationRoutes];
	}

	return useRoutes(routes);
}
