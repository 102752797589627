import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Grid, Typography } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';
import { gridSpacing } from 'store/constant';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
	<Grid container spacing={gridSpacing}>
		<Grid item xs={3}>
			<Link component={RouterLink} to={DASHBOARD_PATH}>
				<Logo />
			</Link>
		</Grid>
		<Grid item xs={9}>
			<Typography variant='h1'>SAL 2.0</Typography>
		</Grid>
	</Grid>
);

export default LogoSection;
