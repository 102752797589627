import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { LibraryProvider } from 'contexts/LibraryContext';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

// widget routing
const WidgetStatistics = Loadable(lazy(() => import('views/widget/Statistics')));
const WidgetData = Loadable(lazy(() => import('views/widget/Data')));
const WidgetChart = Loadable(lazy(() => import('views/widget/Chart')));

// application - user social & account profile routing
const AppUserSocialProfile = Loadable(lazy(() => import('views/application/users/social-profile')));
const AppUserAccountProfile1 = Loadable(
	lazy(() => import('views/application/users/account-profile/Profile1'))
);

const RFPContentLibrary = Loadable(lazy(() => import('views/content-library')));
const UserFTETable = Loadable(lazy(() => import('views/admin/projections/UserFTETable')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
	path: '/',
	element: (
		<AuthGuard>
			<MainLayout />
		</AuthGuard>
	),
	children: [
		{
			path: '/',
			element: <DashboardAnalytics />
		},
		{
			path: '/dashboard',
			element: <DashboardAnalytics />
		},
		{
			path: '/rfp-content-library',
			element: (
				<LibraryProvider>
					<RFPContentLibrary />
				</LibraryProvider>
			)
		},
		{
			path: '/user/ftes/:id',
			element: <UserFTETable />
		},
		{
			path: '/widget/statistics',
			element: <WidgetStatistics />
		},
		{
			path: '/widget/data',
			element: <WidgetData />
		},
		{
			path: '/widget/chart',
			element: <WidgetChart />
		},

		{
			path: '/user/social-profile/:tab',
			element: <AppUserSocialProfile />
		},
		{
			path: '/user/account-profile/profile1',
			element: <AppUserAccountProfile1 />
		}
	]
};

export default MainRoutes;
