import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useAuth from 'hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
	const { user } = useAuth();
	let menuArr;

	if (!user?.permissions?.includes('Admin')) {
		menuArr = menuItem.items.filter((item) => item.id !== 'admin');
	} else {
		menuArr = menuItem.items;
	}

	const navItems = menuArr.map((item) => {
		switch (item.type) {
			case 'group':
				return <NavGroup key={item.id} item={item} />;
			default:
				return (
					<Typography key={item.id} variant='h6' color='error' align='center'>
						Menu Items Error
					</Typography>
				);
		}
	});

	return <>{navItems}</>;
};

export default memo(MenuList);
