import { collection, query, where, getDocs, doc, updateDoc, addDoc } from 'firebase/firestore';
import { db } from 'utils/firebaseInitialize';
import { transformToTimestamp } from 'utils/utilFunctions';

const documentCol = collection(db, 'documents');

export const getContracts = async (opp) => {
	const q = query(
		documentCol,
		where('documentType.value', '==', 'signedContractScope'),
		where('opportunityId', '==', opp)
	);

	const querySnapshot = await getDocs(q);

	if (querySnapshot.empty) {
		return [];
	} else {
		const data = querySnapshot.docs.map((doc) => {
			return { id: doc.id, ...doc.data() };
		});

		return data;
	}
};

export const createDoc = async (data) => {
	data.dateCreated = transformToTimestamp(new Date());
	const newID = await addDoc(documentCol, data);
	return newID;
};
