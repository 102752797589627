// action - state management
import { SET_DATA, SET_TYPE, RESET_DATA, CLEAR_OBJECT } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
	data: {},
	type: ''
};

const dataReducer = (state = initialState, payload) => {
	switch (payload.type) {
		case SET_DATA: {
			const { object } = payload;
			return {
				...state,
				data: { ...object }
			};
		}
		case CLEAR_OBJECT: {
			return {
				...state,
				data: {}
			};
		}
		case SET_TYPE: {
			const { dataType } = payload;
			return {
				...state,
				type: dataType
			};
		}
		case RESET_DATA: {
			return initialState;
		}
		default: {
			return { ...state };
		}
	}
};

export default dataReducer;
