import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { InnerErrorBoundary } from './InnerErrorBoundary';

const ErrorBoundary = ({ children }) => {
	const [hasError, setHasError] = useState(false);
	const location = useLocation();

	useEffect(() => {
		if (hasError) setHasError(false);
	}, [location.key]);

	return (
		<InnerErrorBoundary hasError={hasError} setHasError={setHasError}>
			{children}
		</InnerErrorBoundary>
	);
};

export default ErrorBoundary;
