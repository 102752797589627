// action - state management
import { SET_OPP, RESET_OPP, SET_EDIT, CLEAR_EDIT } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
	bpa: '',
	budgetType: '',
	chance: { id: '', name: '' },
	client: { id: '', name: '' },
	dateQnA: '',
	decisionDueDate: '',
	description: '',
	dueDate: '',
	endClient: { id: '', name: '' },
	estimatedBudgetAmount: 0,
	estimatedBudgetAmountNotes: '',
	estimatedProjectLength: '',
	gsaSchedule: '',
	initialThoughts: '',
	linkToPosting: '',
	opportunityOwner: { id: '', name: '' },
	opportunityStatus: { id: '', name: '' },
	pointsLostOnSections: [],
	primaryTeam: { id: '', name: '' },
	projectType: { id: '', name: '' },
	rfpManager: { id: '', name: '' },
	role: '',
	solicitationName: '',
	solicitationNumber: '',
	source: { id: '', name: '' },
	subContractor: [],
	submissionMethod: '',
	teamsInvolved: [],
	type: 'Opportunity',
	winningFirm: { id: '', name: '' },
	winningAmount: '',
	pointsLostOnSections: '',
	notesLostDropped: '',
	isRecompete: false,
	originalOpportunity: { id: '', name: '' }
};

const opportunityReducer = (state = initialState, payload) => {
	switch (payload.type) {
		case SET_OPP: {
			const { object } = payload;
			return {
				...state,
				...object
			};
		}
		case SET_EDIT: {
			const { object } = payload;
			return {
				...state,
				edited: {
					...object
				}
			};
		}
		case CLEAR_EDIT: {
			return {
				...state,
				edited: {}
			};
		}
		case RESET_OPP: {
			return initialState;
		}
		default: {
			return { ...state };
		}
	}
};

export default opportunityReducer;
