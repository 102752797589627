// material-ui
import { useTheme } from '@mui/material/styles';
import anlarLogo from 'assets/images/anlarLogos/anlarLogo.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
	const theme = useTheme();

	return <img src={anlarLogo} alt='SAL 2.0' width='50' />;
};

export default Logo;
