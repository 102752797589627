export const stateOptions = [
	{ label: 'Alaska', value: 'Alaska' },
	{ label: 'Alabama', value: 'Alabama' },
	{ label: 'Arkansas', value: 'Arkansas' },
	{ label: 'Arizona', value: 'Arizona' },
	{ label: 'California', value: 'California' },
	{ label: 'Colorado', value: 'Colorado' },
	{ label: 'Connecticut', value: 'Connecticut' },
	{ label: 'District of Columbia', value: 'District of Columbia' },
	{ label: 'Delaware', value: 'Delaware' },
	{ label: 'Florida', value: 'Florida' },
	{ label: 'Georgia', value: 'Georgia' },
	{ label: 'Hawaii', value: 'Hawaii' },
	{ label: 'Iowa', value: 'Iowa' },
	{ label: 'Idaho', value: 'Idaho' },
	{ label: 'Illinois', value: 'Illinois' },
	{ label: 'Indiana', value: 'Indiana' },
	{ label: 'Kansas', value: 'Kansas' },
	{ label: 'Kentucky', value: 'Kentucky' },
	{ label: 'Louisiana', value: 'Louisiana' },
	{ label: 'Massachusetts', value: 'Massachusetts' },
	{ label: 'Maryland', value: 'Maryland' },
	{ label: 'Maine', value: 'Maine' },
	{ label: 'Michigan', value: 'Michigan' },
	{ label: 'Minnesota', value: 'Minnesota' },
	{ label: 'Missouri', value: 'Missouri' },
	{ label: 'Mississippi', value: 'Mississippi' },
	{ label: 'Montana', value: 'Montana' },
	{ label: 'North Carolina', value: 'North Carolina' },
	{ label: 'North Dakota', value: 'North Dakota' },
	{ label: 'Nebraska', value: 'Nebraska' },
	{ label: 'New Hampshire', value: 'New Hampshire' },
	{ label: 'New Jersey', value: 'New Jersey' },
	{ label: 'New Mexico', value: 'New Mexico' },
	{ label: 'Nevada', value: 'Nevada' },
	{ label: 'New York', value: 'NewYork' },
	{ label: 'Ohio', value: 'Ohio' },
	{ label: 'Oklahoma', value: 'Oklahoma' },
	{ label: 'Oregon', value: 'Oregon' },
	{ label: 'Pennsylvania', value: 'Pennsylvania' },
	{ label: 'Rhode Island', value: 'Rhode Island' },
	{ label: 'South Carolina', value: 'South Carolina' },
	{ label: 'South Dakota', value: 'South Dakota' },
	{ label: 'Tennessee', value: 'Tennessee' },
	{ label: 'Texas', value: 'Texas' },
	{ label: 'Utah', value: 'Utah' },
	{ label: 'Virginia', value: 'Virginia' },
	{ label: 'Vermont', value: 'Vermont' },
	{ label: 'Washington', value: 'Washington' },
	{ label: 'Wisconsin', value: 'Wisconsin' },
	{ label: 'West Virginia', value: 'West Virginia' },
	{ label: 'Wyoming', value: 'Wyoming' }
];

export const submissionMethodOptions = [
	{
		label: 'Mail/Drop it off',
		value: 'Mail/Drop it off'
	},
	{
		label: 'Email/Electronic',
		value: 'Email/Electronic'
	}
];

export const gsaScheduleOptions = [
	{
		label: 'Yes',
		value: 'Yes'
	},
	{
		label: 'No',
		value: 'No'
	}
];

export const roleOptions = [
	{
		label: 'Prime',
		value: 'Prime'
	},
	{
		label: 'Sub',
		value: 'Sub'
	}
];

export const statusOptions = [
	{
		label: 'Active',
		value: 'Active'
	},
	{
		label: 'Inactive',
		value: 'Inactive'
	}
];

export const budgetTypeOptions = [
	{
		label: 'Submitted',
		value: 'Submitted'
	},
	{
		label: 'Estimated',
		value: 'Estimated'
	}
];

export const invoiceFrequencyOptions = [
	{
		label: 'Monthly',
		value: 'Monthly'
	},
	{
		label: 'Quarterly',
		value: 'Quarterly'
	},
	{
		label: 'Annually',
		value: 'Anually'
	}
];

export const invoiceTypeOptions = [
	{
		label: 'Fixed Price/Milestone',
		value: 'Fixed Price/Milestone'
	},
	{
		label: 'Time & Materials',
		value: 'Time & Materials'
	}
];

export const monthOptions = [
	{ label: 'January', value: '1' },
	{ label: 'February', value: '2' },
	{ label: 'March', value: '3' },
	{ label: 'April', value: '4' },
	{ label: 'May', value: '5' },
	{ label: 'June', value: '6' },
	{ label: 'July', value: '7' },
	{ label: 'August', value: '8' },
	{ label: 'September', value: '9' },
	{ label: 'October', value: '10' },
	{ label: 'November', value: '11' },
	{ label: 'December', value: '12' }
];

export const documentTypes = [
	{
		label: 'Letter of Commitment',
		value: 'letterOfCommitment'
	},
	{
		label: 'RFI Response or Capability Statements',
		value: 'rfiResponseOrCapability'
	},
	{
		label: 'RFP TECHNICAL PROPOSAL',
		value: 'rfpTechnical'
	},
	{
		label: 'RFP BUSINESS PROPOSAL',
		value: 'rfpBusiness'
	},
	{
		label: 'Finalist Materials',
		value: 'finalistMaterials'
	},
	{
		label: 'Reviewer Scores and Comments',
		value: 'reviewerScoresComments'
	},
	{
		label: 'Non-AnLar/Ascend Proposal',
		value: 'nonAnlarAscend'
	},
	{
		label: 'Conflict of Interest, Ethics Agreement',
		value: 'conflictOfInterest'
	},
	{
		label: 'Misc Boilerplate Clauses, Federal FARs',
		value: 'miscBoilerplate'
	},
	{
		label: 'Signed Contract or Scope of Work',
		value: 'signedContractScope'
	},
	{
		label: 'Invoices',
		value: 'invoices'
	},
	{
		label: 'Monthly Reports',
		value: 'monthlyReports'
	},
	{
		label: 'CPARS',
		value: 'cpars'
	},

	{
		label: 'RFP',
		value: 'rfp'
	}
];
