import _ from 'lodash';
import { Timestamp } from 'firebase/firestore';

export const handleChangeLog = ({ values, originalRecord, user }) => {
	let changeLog = originalRecord.changeLog || [];
	let edited = {};

	for (const key in values) {
		const isEqual = _.isEqual(originalRecord[key], values[key]);
		if (!isEqual && key !== 'changeLog') {
			edited[key] = values[key];
			changeLog.push({
				dateUpdated: Timestamp.fromDate(new Date()),
				changedBy: user.id,
				field: key,
				oldValue: originalRecord[key],
				newValue: values[key]
			});
		}
	}
	return { changeLog, edited };
};
