// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
	Avatar,
	Button,
	Card,
	CardContent,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Stack,
	Typography
} from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import useAuth from 'hooks/useAuth';
import { getMonthArray } from 'utils/utilFunctions';
import { IconReport } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';

// styles
const CardStyle = styled(Card)(({ theme }) => ({
	background:
		theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.warning.light,
	marginTop: '16px',
	marginBottom: '16px',
	overflow: 'hidden',
	position: 'relative',
	'&:after': {
		content: '""',
		position: 'absolute',
		width: '200px',
		height: '200px',
		border: '19px solid ',
		borderColor:
			theme.palette.mode === 'dark' ? theme.palette.warning.main : theme.palette.warning.main,
		borderRadius: '50%',
		top: '65px',
		right: '-150px'
	},
	'&:before': {
		content: '""',
		position: 'absolute',
		width: '200px',
		height: '200px',
		border: '3px solid ',
		borderColor:
			theme.palette.mode === 'dark' ? theme.palette.warning.main : theme.palette.warning.main,
		borderRadius: '50%',
		top: '145px',
		right: '-70px'
	}
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const FTESection = () => {
	const theme = useTheme();
	const { user } = useAuth();
	const navigate = useNavigate();
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();

	const rollingMonths = getMonthArray(3, new Date().getMonth());

	const total = (arr, field) => arr.reduce((acc, next) => (acc += next[field]), 0);

	const filtered = rollingMonths.map((month) => {
		const fteMonthMatches = user.ftes.filter(
			(fte) => fte.month.label === month.month && fte.year == currentYear
		);

		if (fteMonthMatches.length > 0) {
			return { month: month.month, total: total(fteMonthMatches, 'percentAllocated') };
		} else {
			return { month: month.month, total: 0 };
		}
	});

	const handleNavigate = () => {
		navigate(`/user/ftes/${user.id}`);
	};

	return (
		<CardStyle>
			<CardContent>
				<Grid container direction='column' spacing={2}>
					<Grid item>
						<Typography variant='h4'>Twelve Month FTE Totals: </Typography>
					</Grid>
					<Grid item>
						<Typography variant='subtitle2' color={'grey.900'} sx={{ opacity: 0.6 }}>
							{`${rollingMonths[0].month} - ${
								rollingMonths[rollingMonths.length - 1].month
							} ${currentYear}`}
						</Typography>
					</Grid>
					<Grid item>
						<List sx={{ width: '100%', maxWidth: 360 }}>
							{filtered.map((item) => (
								<ListItem key={item.month}>
									<ListItemAvatar>
										<Avatar
											sx={{
												width: 32,
												height: 32,
												bgcolor: theme.palette.warning.main,
												color: theme.palette.warning.light
											}}
										>
											{item.month[0]}
											{/* <IconReport /> */}
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={item.month}
										secondary={`${item.total * 100}%`}
									/>
								</ListItem>
							))}
						</List>
					</Grid>
					<Grid item>
						<Stack direction='row'>
							<AnimateButton>
								<Button
									variant='contained'
									color='warning'
									sx={{ boxShadow: 'none' }}
									onClick={handleNavigate}
								>
									View All FTE Allocations
								</Button>
							</AnimateButton>
						</Stack>
					</Grid>
				</Grid>
			</CardContent>
		</CardStyle>
	);
};

export default FTESection;
