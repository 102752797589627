// action - state management
import { LOGIN, LOGOUT, REGISTER, SET_FTE, UPDATE_USER } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
	isLoggedIn: false,
	isInitialized: false,
	user: null
};

const accountReducer = (state = initialState, action) => {
	switch (action.type) {
		case REGISTER: {
			const { user } = action.payload;
			return {
				...state,
				user
			};
		}
		case LOGIN: {
			const { user } = action.payload;
			return {
				...state,
				isLoggedIn: true,
				isInitialized: true,
				user
			};
		}
		case LOGOUT: {
			return {
				...state,
				isInitialized: true,
				isLoggedIn: false,
				user: null
			};
		}
		case SET_FTE: {
			const { user } = action.payload;
			return {
				...state,
				user
			};
		}
		case UPDATE_USER: {
			const { settings } = action.payload;
			console.log('user', settings);

			return {
				...state,
				user: {
					...state.user,
					...settings
				}
			};
		}
		default: {
			return { ...state };
		}
	}
};

export default accountReducer;
