// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { AppProvider } from 'contexts/AppContext';
import { UsersnapProvider } from 'contexts/UsersnapContext';
import ErrorBoundary from 'views/pages/maintenance/ErrorBoundary';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => (
	<ThemeCustomization>
		{/* RTL layout */}
		<RTLLayout>
			<Locales>
				<NavigationScroll>
					<AuthProvider>
						<AppProvider>
							<>
								<ErrorBoundary>
									<Routes />
									<Snackbar />
									<UsersnapProvider />
								</ErrorBoundary>
							</>
						</AppProvider>
					</AuthProvider>
				</NavigationScroll>
			</Locales>
		</RTLLayout>
	</ThemeCustomization>
);

export default App;
