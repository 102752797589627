import useAuth from 'hooks/useAuth';
import { useEffect, useState, useContext, createContext } from 'react';
import { usersnapProjectKey } from 'store/constant';

const UsersnapContext = createContext(null);

export const UsersnapProvider = ({ initParams = {}, children }) => {
	const [usersnapApi, setUsersnapApi] = useState(null);
	const { user } = useAuth();

	useEffect(() => {
		if (!user?.id) return;
		// if (!user?.sysAdmin) return;

		let usersnapApi = null;
		window.onUsersnapCXLoad = function (api) {
			api.init(initParams);
			usersnapApi = api;
			setUsersnapApi(api);
		};

		const script = document.createElement('script');
		script.defer = 1;
		script.src = `https://widget.usersnap.com/load/${usersnapProjectKey}?onload=onUsersnapCXLoad`;
		document.head.appendChild(script);

		return () => {
			if (usersnapApi) {
				usersnapApi.destroy();
			}
			script.remove();
		};
	}, [user]);

	return <UsersnapContext.Provider value={usersnapApi}>{children}</UsersnapContext.Provider>;
};

export function useUsersnapApi() {
	return useContext(UsersnapContext);
}
